import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import {
  Hero,
  About,
  Services,
  Location,
  Team,
  Cooperation,
  Contact,
} from "page_components/home"

const Home = ({ data, location }) => {
  const pageData = data.allWpPage.nodes[0]
  const teamPageData = data.teamPage.nodes[0]
  const teamData = data.allWpTeam.nodes
  const servicesData = data.allWpService.nodes

  return (
    <Layout
      seo={{
        title: 'Rozwiązania logistyczne',
      }}
      location={location}
      isHome
    >
      <Hero pageData={pageData} />
      <About pageData={pageData} />
      <Services pageData={servicesData} isHome isInverted/>
      <Location pageData={pageData} />
      <Team pageData={teamPageData} teamData={teamData} />
      <Cooperation pageData={pageData} />
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String) {
    allWpPage(filter: { id: { eq: "cG9zdDoxMA==" } }) {
      nodes {
        id
        acfHome {
          heroHeader
          locationHeader
          locationDescription
          cooperationHeader
          cooperationDescription
          aboutHeader
          aboutDescription
        }
        title
      }
    }

    teamPage: allWpPage(filter: { id: { eq: "cG9zdDoxNzY=" } }) {
      nodes {
        id
        title
        acfTeam {
          heroHeader
        }
      }
    }

    allWpTeam(
      filter: { language: { slug: { eq: $language } } }
      sort: { fields: date }
    ) {
      nodes {
        title
        acfTeam {
          image {
            sourceUrl
          }
          description
          linkedin
          role
        }
        id
      }
    }

    allWpService(
      sort: { fields: date, order: ASC }
      filter: { language: { slug: { eq: $language } } }
    ) {
      nodes {
        title
        slug
        acfServices {
          tileImage {
            sourceUrl
          }
        }
      }
    }
  }
`

export default Home
